import { gql } from '@apollo/client';

export const singleGuidebooks = gql`
query getGuidebookSingleData($url: ID!) {
	guidebooks(idType: URI, id: $url) {
		modified
		slug
		guidebooksId
		guidebooks {
			guidebookTitle
			coverNav
			guideNav
			guidebookSocialHashtag
			guidebookLogo {
				srcSet(size: MC_XSTRASMALL)
				sourceUrl(size: MC_XSTRASMALL)
				altText
			}
			guidebookFeaturedImage {
				srcSet(size: MC_GUIDEBOOK_THIRD)
				sourceUrl(size: MC_GUIDEBOOK_THIRD)
				sizes(size: MC_GUIDEBOOK_THIRD)
				altText
			}
			guidebookLogobar
			guidebookPrimaryColor
			guidebookSecondaryColor
			guidebookGuideid
			templateGuideEmailFormId
			templateGuideEmailSuccessMessage
			templateGuideEmailHeading
			templateGuideEmailDesc
			# guidebookPasswordProtection
			guidebookTipPage
			templateTipSuggestionsHeading
			templateTipNav
			templateTips {
				heading
				description
			}
			guidebookWelcome{
			... on GuidebookTemplates{
				id
				guidebookTemplates{
					templateWelcomeImage{
						srcSet(size: MC_GUIDEBOOK_THIRD)
						sourceUrl(size: MC_GUIDEBOOK_THIRD)
						sizes(size: MC_GUIDEBOOK_THIRD)
						altText
					}
					templateWelcomeHeading
					templateWelcomeNav
					templateWelcomePersonalLetter
					templateWelcomeNameSignature{
						srcSet(size: MC_XSTRASMALL)
						sourceUrl
						sizes
						altText
					}
					templateWelcomeName
					templateWelcomeTitle
				}
			}
			}
			guidebookTourGuide{
			... on GuidebookGuides{
				guidebookGuide_tour: id
				title
				guidebookGuide{
					templateGuideImage{
						srcSet(size: MC_GUIDEBOOK_THIRD)
						sourceUrl(size: MC_GUIDEBOOK_THIRD)
						sizes(size: MC_GUIDEBOOK_THIRD)
						altText
					}
					templateGuideHeading
					templateGuideDescription
					templateGuideFavorites {
						heading
						description
					}
					templateTipHeading
					templateTipContent
					templateTipsTipOptions {
						paypayLink
						venmoLink
						zelleLink
						chasepayLink
					}
				}
			}
			}
			guidebookTodaysTour{
			... on GuidebookTemplates{
				id
				guidebookTemplates{
				templateTourImage{
					srcSet(size: MC_GUIDEBOOK_THIRD)
					sourceUrl(size: MC_GUIDEBOOK_THIRD)
					sizes(size: MC_GUIDEBOOK_THIRD)
					altText
				}
				templateTourHeading
				templateTourNav
				templateTourDescription
				templateTourFaqs {
					answer
					question
				}
				}
			}
			}
			guidebookLeaveReview{
			... on GuidebookTemplates{
				id
				guidebookTemplates{
				templateReviewHeading
				templateReviewNav
				templateReviewDescription
				templateReviewHeader
				reviewLinks {
					facebookReviewLink
					googleReviewLink
					tripAdvisorReviewLink
					yelpReviewLink
				}
				}
			}
			}
			# guidebookTourQuesiton{
			# ... on GuidebookTemplates{
			# 	id
			# 	guidebookTemplates{
			# 	templateQuestionHeading
			# 	templateQuestionDescription
			# 	templateQuesitonStopOptions {
			# 		stopName
			# 	}
			# 	}
			# }
			# }
			guidebookTourClose{
			... on GuidebookTemplates{
				id
				guidebookTemplates{
					templateCloseImage{
						srcSet(size: MC_GUIDEBOOK_THIRD)
						sourceUrl(size: MC_GUIDEBOOK_THIRD)
						sizes(size: MC_GUIDEBOOK_THIRD)
						altText
					}
					templateCloseHeading
					templateCloseNav
					templateCloseDescription
					templateCloseCoupon {
						heading
						description
						additionalInfo
					}
					templateSendLink
					templateSendFriend
					templateCloseMore{
						title
						url
					}
					templateCloseRecommendaionsTitle
					templateCloseRecommendaions{
						title
						url
					}
				}
			}
			}
			guidebookHeading
			stopNav
			guidebookDescription
			guidebookStopsFeaturedImage{
				srcSet(size: MC_GUIDEBOOK_FULL)
				sourceUrl(size: MC_GUIDEBOOK_FULL)
				sizes(size: MC_GUIDEBOOK_FULL)
				altText
			}
			mapOrImage
			stopMap
			stopMapImg{
				srcSet(size: MC_GUIDEBOOK_FULL)
				sourceUrl(size: MC_GUIDEBOOK_FULL)
				sizes(size: MC_GUIDEBOOK_FULL)
				altText
			}
			stopMapImgLink
			guidebookStops {
			... on GuidebookStops {
				id
				guidebookStops {
				stopsPreviewImage {
					srcSet(size: MC_GUIDEBOOK_THIRD)
					sourceUrl(size: MC_GUIDEBOOK_THIRD)
					sizes(size: MC_GUIDEBOOK_THIRD)
					smallSrcSet: srcSet(size: MC_SMALL)
					smallUrl: sourceUrl(size: MC_SMALL)
					smallSize: sizes(size: MC_SMALL)
					altText
				}
				stopsPreviewHeading
				stopsPreviewDescription
				imageGallery {
					srcSet(size: MC_GUIDEBOOK_THIRD)
					sourceUrl(size: MC_GUIDEBOOK_THIRD)
					sizes(size: MC_GUIDEBOOK_THIRD)
					altText
				}
				stopsHeading
				stopsDescription
				stopsFeaturedImage {
					sourceUrl(size: MC_GUIDEBOOK_THIRD)
					sizes(size: MC_GUIDEBOOK_THIRD)
					largeSource: sourceUrl(size: MC_SINGLE_BLOG)
					largeSize: sizes(size: MC_SINGLE_BLOG)
					altText
					smallUrl: sourceUrl(size: MC_SMALL)
					smallSize: sizes(size: MC_SMALL)
				}
				stopsFeaturedHeading
				stopsFeaturedDescription
				stopsCouponOfferHeading
				stopsCouponOfferDescription
				stopsCouponOfferAdditionalInfo
				}
			}
			}
		}
  	}
}`;

export const adminSettings = gql`
query getAdminSettingsData{
	guidebookOptions{
		guidebookOptions{
			adminFav{
				srcSet(size: MC_XSTRASMALL)
				sourceUrl(size: MC_XSTRASMALL)
				altText
			}
			adminLogobar
			adminLogo {
				srcSet(size: MC_XSTRASMALL)
				sourceUrl(size: MC_XSTRASMALL)
				altText
			}
			adminCompanyName
			adminPrimaryColor
			adminSecondaryColor
			adminGoogletranslate
			adminSocialLinks {
				facebookLink
				instagramLink
				twitterLink
			}
			adminReviewHeader
			adminReviewLinks {
				facebookReviewLink
				googleReviewLink
				tripAdvisorReviewLink
				yelpReviewLink
			}
		}
  	}
	generalSettings{
		description
	}
}`;

export const allGuides = gql`
query getAllGuides {
	allGuidebookGuides {
    nodes {
	  guidebookGuide_tour: id
	  title
      guidebookGuide {
        templateGuideImage {
          srcSet(size: MC_GUIDEBOOK_THIRD)
          sourceUrl(size: MC_GUIDEBOOK_THIRD)
          sizes(size: MC_GUIDEBOOK_THIRD)
          altText
        }
        templateGuideHeading
        templateGuideDescription
        templateGuideFavorites {
          heading
          description
        }
        templateTipHeading
		templateTipContent
        templateTipsTipOptions {
          paypayLink
          venmoLink
          zelleLink
          chasepayLink
		  cashLink
        }
        templateGuideId
      }
    }
  }
}`;

export const allGuidebooks = gql`
query getAllGuidebooks {
	allGuidebooks {
		nodes {
			id
			title
		}
	}
}`;