import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AnimatePresence, motion } from 'framer-motion';
import { sendFriend } from '../actions/motion';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faShareSquare } from "@fortawesome/pro-solid-svg-icons";

import styled from 'styled-components';

// actions
import { GAevent } from '../actions';

const FontIconColor = styled('div')`
    color: ${props => props.color};
`;

const FriendBtn = styled('button')`
    background-color: ${props => props.color};
    &:hover {
        color: #fff;
    }
`;

const ThankYou = ({
    thankData: { 0: { guidebookTemplates: { templateCloseImage, templateCloseHeading, templateCloseDescription, templateCloseCoupon, templateSendLink, templateSendFriend, templateCloseMore, templateCloseRecommendaionsTitle, templateCloseRecommendaions } } }
}) => {

    const { templateGuideHeading } = useSelector(state => state.data.currentGuide);
    const { adminCompanyName, adminPrimaryColor } = useSelector(state => state.data.admin);
    const { 0: { guidebookPrimaryColor } } = useSelector(state => state.data.guidebooks);

    const copyInput = `${templateSendLink ? templateSendLink : ''} ${templateSendFriend ? templateSendFriend : ''}`;
    const [btnText, setBtnText] = useState('Copy Message');
    const [btnClick, setBtnClick] = useState(false);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        if (btnClick) {
            const textFriend = document.querySelector('textarea');
            textFriend.focus();
            setTimeout(() => {
                setBtnClick(false);
            }, 1000);
        }
    }, [btnClick]);


    const [showCoupon, setShowCoupon] = useState(true);
    useEffect(() => {
        let coupon = false;
        if (templateCloseCoupon.heading) {
            coupon = true;
        } else if (templateCloseCoupon.description) {
            coupon = true;
        }
        setShowCoupon(coupon);
    }, [templateCloseCoupon]);

    return (
        <div className="container-fluid">
            <img id="hero-img" className="img-fluid" width="767" height="800" src={templateCloseImage?.sourceUrl} srcSet={templateCloseImage?.srcSet} alt={templateCloseImage?.altText} />
            <div id="content-container">
                <div className="content-box">
                    <div className="row mb20">
                        <div className="col">
                            <h2>{templateCloseHeading}</h2>
                            <div className="mb30" dangerouslySetInnerHTML={{ __html: templateCloseDescription }} />
                            <p className="cursive h2">{adminCompanyName}</p>
                        </div>
                    </div>
                </div>
            </div>
            {showCoupon &&
                <div className="content-container pt0">
                    <div className="content-box">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb20">{templateCloseCoupon.heading}</h3>
                                <div className="mb20" dangerouslySetInnerHTML={{ __html: templateCloseCoupon.description }} />
                                <div className="mb20">
                                    {/* <a href="#" className="btn btn-block btn-social btn-redeem">Redeem</a> */}
                                    <FriendBtn id="btn-friend-main" className="btn btn-block btn-social btn-friend" onClick={() => {
                                        setBtnClick(true);
                                        setShowText(true);
                                        GAevent('User', 'Send Friend', 'Clicked');
                                        GAevent(templateGuideHeading, 'Send Friend', 'Clicked');
                                    }} color={guidebookPrimaryColor ? guidebookPrimaryColor : adminPrimaryColor ? adminPrimaryColor : ''}><FontAwesomeIcon icon={faShareSquare} className="mr10" />Send to a friend</FriendBtn>
                                    <AnimatePresence >
                                        {showText &&
                                            <motion.div key="text-area" initial={'initial'} animate={'animate'} variants={sendFriend}>
                                                <textarea className="form-control mb10 mt10" type="text" defaultValue={copyInput} autoFocus disabled />
                                                <CopyToClipboard text={copyInput} onCopy={() => {
                                                    setBtnText('Message Copied');
                                                    GAevent('User', 'Send Friend', 'Copied');
                                                    GAevent(templateGuideHeading, 'Send Friend', 'Copied');
                                                    setTimeout(() => {
                                                        setBtnText('Copy Message');
                                                    }, 1000);
                                                }}>
                                                    <FriendBtn className="btn btn-block btn-social btn-friend" color={guidebookPrimaryColor ? guidebookPrimaryColor : adminPrimaryColor ? adminPrimaryColor : ''}>{btnText}</FriendBtn>
                                                </CopyToClipboard>
                                            </motion.div>
                                        }
                                    </AnimatePresence>
                                </div>
                                <p className="text-center"><small>{templateCloseCoupon.additionalInfo}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {templateCloseMore?.length > 0 &&
                <div className="content-container">
                    <div className="content-box">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb20">More from {adminCompanyName}</h3>
                            </div>
                        </div>
                        {templateCloseMore.map((item, index) =>
                            <a className="link-list" key={`more-${index}`} href={item.url} target="_blank" rel="noopener noreferrer">
                                <div className="row align-items-center">
                                    <div className="col">
                                        {item.title}
                                    </div>
                                    <div className="col-auto"><FontIconColor color={guidebookPrimaryColor ? guidebookPrimaryColor : adminPrimaryColor ? adminPrimaryColor : ''}><FontAwesomeIcon icon={faChevronRight} /></FontIconColor></div>
                                </div>
                            </a>
                        )}
                    </div>
                </div>
            }
            {templateCloseRecommendaions?.length > 0 &&
                <div className="content-container">
                    <div className="content-box">
                        <div className="row">
                            <div className="col">
                                <h3 className="mb20">{templateCloseRecommendaionsTitle}</h3>
                            </div>
                        </div>
                        {templateCloseRecommendaions.map((item, index) =>
                            <a className="link-list" key={`recommendations-${index}`} href={item.url} target="_blank" rel="noopener noreferrer">
                                <div className="row align-items-center">
                                    <div className="col">
                                        {item.title}
                                    </div>
                                    <div className="col-auto"><FontIconColor color={guidebookPrimaryColor ? guidebookPrimaryColor : adminPrimaryColor ? adminPrimaryColor : ''}><FontAwesomeIcon icon={faChevronRight} /></FontIconColor></div>
                                </div>
                            </a>
                        )}
                    </div>
                </div>
            }
        </div >
    )
}

export default ThankYou
